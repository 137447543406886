const intitialState = {
  message: '',
  selectedFile: '',
};

const initialForm = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

export { intitialState, initialForm };
