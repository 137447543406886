export const FETCH_ALL = 'FETCH_ALL';
export const RESET_POSTS = 'RESET_POSTS';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_ALL_PROFILE = 'FETCH_ALL_PROFILE';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const LIKE = 'LIKE';
export const HEART = 'HEART';
export const DELETE = 'DELETE';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const COMMENT = 'COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';
export const ERROR = 'ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const ADD_PAGE = 'ADD_PAGE';
export const ADD_POST_PAGE = 'ADD_POST_PAGE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const INSERT_USER = 'INSERT_USER';
export const FOLLOW_USER = 'FOLLOW_USER';
export const FOLLOWERS_BY_ID = 'FOLLOWERS_BY_ID';
export const FOLLOWING_BY_ID = 'FOLLOWING_BY_ID';
export const SET_PAGE_TO_DEFAULT = 'SET_PAGE_TO_DEFAULT';
export const CLEAR_USERS = 'CLEAR_USERS';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';

// Page
export const USER_POSTS_PAGE = 'USER_POSTS_PAGE';
export const USER_POST_DEFAULT_PAGE = 'USER_POST_DEFAULT_PAGE';
export const PAGE_DEFAULT_NUMBER = 'PAGE_DEFAULT_NUMBER';

// id
export const CURRENT_POST_ID = 'CURRENT_POST_ID';
export const CURRENT_COMMENT_ID = 'CURRENT_COMMENT_ID';

// Loading
export const START_LOADING = 'START_LOADING';
export const FINISH_LOADING = 'FINISH_LOADING';
export const POST_LOADING = 'POST_LOADING';
export const POST_END_LOADING = 'POST_END_LOADING';
export const COMMENT_START_LOADING = 'COMMENT_START_LOADING';
export const COMMENT_END_LOADING = 'COMMENT_END_LOADING';
export const USER_LOADING = 'USER_LOADING';
export const FINISH_USER_LOADING = 'FINISH_USER_LOADING';
export const AUTH_START_LOADING = 'AUTH_START_LOADING';
export const AUTH_FINISH_LOADING = 'AUTH_FINISH_LOADING';
export const USER_POSTS_LOADING = 'USER_POSTS_LOADING';
// Modal
export const CREATE_MODAL = 'CREATE_MODAL';
export const ACTION_MODAL = 'ACTION_MODAL';
